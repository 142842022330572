import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                  />                  
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                  <a href={post.frontmatter.btnlink} className="nav-link btn btn-sm-block btn-mdb-color" target="_blank" rel="noopener" >
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mr-2" />{post.frontmatter.btntext}
                </a>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(frontmatter: { name: { eq: "careers" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        openingpara
        alttext
        ctatext
        ctaslug
        btnlink
        btntext
      }
      html
    }
  }
`
